<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: any
        value?: any
        name: string
        label?: string
        error?: string
        disabled?: boolean
        labelClass?: string
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
    }

    const props = withDefaults(defineProps<Props>(), {
        value: undefined,
        label: undefined,
        error: '',
        disabled: false,
        labelClass: undefined,
    })

    const emit = defineEmits<Emit>()

    const inputModel = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            if (props.error) {
                emit('update:error', '')
            }

            emit('update:modelValue', value)
        },
    })

    const style = useCssModule()

    const radioClass = computed<string[]>(() => {
        const classes = [ style['radio'] ]

        if (inputModel.value === props.value) {
            classes.push(style['radio--active'])
        }

        if (props.disabled) {
            classes.push(style['radio--disabled'])
        }

        return classes
    })
</script>

<template>
    <label :class="radioClass">
        <span
            key="input-wrapper"
            :class="$style['radio__wrapper']"
        >
            <input
                ref="inputRef"
                key="input"
                v-model="inputModel"
                :value="props.value"
                :name="props.name"
                type="radio"
                :disabled="props.disabled"
                :class="$style['radio__wrapper__input']"
                autocomplete="off"
                @click="event => event.target.focus()"
            />
        </span>

        <span
            v-if="props.label"
            key="label"
            :class="[ $style['radio__label'], props.labelClass ]"
        >
            <slot name="label-text">
                {{ props.label }}
            </slot>
        </span>

        <AppFormFieldError
            :disabled="props.disabled"
            :error-message="props.error"
        />
    </label>
</template>

<style
    lang="sass"
    module
    scoped
>
    .radio
        display: flex
        align-items: center
        cursor: var(--cursor, pointer)

        --bg-color: transparent
        --border-color: #aaadb8
        --inner-circle-scale: 0
        --inner-circle-bg-color: #000
        --outer-circle-scale: 0
        --outer-circle-bg-color: rgba(220, 224, 227, 0.3)

        &:hover
            --border-color: #000
            --outer-circle-scale: 1.4

        &:active
            --outer-circle-bg-color: #dce0e3

        &--active
            --border-color: #000
            --inner-circle-scale: 1

        &--disabled
            --bg-color: #f6f7f8
            --border-color: #e0e2ea !important
            --inner-circle-bg-color: #e0e2ea
            --outer-circle-scale: 0 !important
            --cursor: not-allowed

        &__wrapper
            position: relative
            display: flex

            &__input
                $outer-circle-size: 20px
                $inner-circle-size: 10px

                appearance: none
                outline: none
                position: relative
                min-width: $outer-circle-size
                min-height: $outer-circle-size
                width: $outer-circle-size
                height: $outer-circle-size
                border: 2px solid var(--border-color)
                background: var(--bg-color)
                border-radius: 50%
                transition: background-color var(--transition-default-duration-with-ease), border-color var(--transition-default-duration-with-ease)
                will-change: background-color, border-color
                cursor: var(--cursor, pointer)

                &:before,
                &:after
                    pointer-events: none

                &:focus
                    --outer-circle-scale: 1.4
                    --outer-circle-bg-color: #dce0e3

                &:before
                    z-index: -1
                    content: ''
                    position: absolute
                    top: -2px
                    left: -2px
                    width: $outer-circle-size
                    height: $outer-circle-size
                    background: var(--outer-circle-bg-color)
                    border-radius: 50%
                    transform: scale(var(--outer-circle-scale))
                    transition: transform var(--transition-default-duration-with-ease)
                    will-change: transform

                &:after
                    content: ''
                    position: absolute
                    top: 3px
                    left: 3px
                    height: $inner-circle-size
                    width: $inner-circle-size
                    background: var(--inner-circle-bg-color)
                    border-radius: 50%
                    transform: scale(var(--inner-circle-scale))
                    transition: transform var(--transition-default-duration-with-ease)
                    will-change: transform

        &__label
            margin-left: 14px
            font-size: 14px
            font-weight: 400
            line-height: 130%
            color: #000
</style>
